$(document).ready(function () {
    let programs_section = $('[data-programs]')
    let programs_info = $('[data-programs-info]')
    let programs_image = $('[data-programs-image]')
    let programs_accordion = $('[data-programs-accordion]')
    let accordion_head = $('[data-programs-accordion-head]')
    let accordion_body = $('[data-programs-accordion-body]')
    let accordion_section = $('[data-programs-accordion-section]')

    if ($(window).width() < 768) {
        programs_info.insertBefore(programs_accordion);
    }

    accordion_head.on('click', function () {
        let parent = $(this).parent();
        let accordion_body_info = parent.find('[data-programs-accordion-body-info]').html();
        let accordion_body_image = parent.find('[data-programs-accordion-body-image]').html();

        if (parent.hasClass('open')) {
            parent.removeClass('open');
            $(this).next('[data-programs-accordion-body]').slideUp();
            programs_section.removeClass('show--info');
            programs_info.empty();
            programs_image.empty();
        } else {
            accordion_section.removeClass('open');
            accordion_body.slideUp();
            parent.addClass('open');
            $(this).next('[data-programs-accordion-body]').slideDown();
            programs_section.addClass('show--info');

            if ($(window).width() > 1100) {
                programs_info.hide().html(accordion_body_info).fadeIn('slow');
                programs_image.hide().html(accordion_body_image).fadeIn('slow');
            } else {
                programs_info.hide().html(accordion_body_image).fadeIn('slow');
                $('html, body').animate({
                    scrollTop: programs_accordion.offset().top - 30
                }, 500);
            }
        }
    });
});
