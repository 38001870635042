$(document).ready(function () {
    $('[data-community-slider]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 1000,
        prevArrow: $('[data-community-btn-prev]'),
        nextArrow: $('[data-community-btn-next]'),
        infinite: false,
        fade: true,
    });
});
