//PLUGINS
import 'slick-carousel';
import './plugins/gallery'
import WOW from './plugins/wow';

//COMMON
import './language-dropdown'
import './programs-accordion'
import './faculty-details'
import './video-players'
import './students-gallery'
import './apple-devices'
import './anchors-links'

//SLIDERS
import './sliders/faculty-slider'
import './sliders/works-slider'
import './sliders/community-slider'

$(document).ready(function ($) {
    let wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: function (box) {
            $(box).addClass('animate__wow-fadeInUp');
        },
    });
    wow.init();
});
