$(document).ready(function () {

    if ($(window).width() <= 960) {
        $('video').each(function () {
            $(this).attr('controls', '');
            $(this).attr('playsinline', '');
            $(this).attr('webkit-playsinline', '');
            $(this).attr('x5-playsinline', '');
            $(this).attr('x5-video-player-type', 'h5');
            $(this).attr('x5-video-player-fullscreen', '');
            $(this).attr('x5-video-orientation', 'portraint');
        });
    }

    $('[data-video-play]').on('click', function () {
        let video = $(this).closest('[data-video]').find('video[data-video-content]').get(0);
        if (video.paused) {
            video.play();
            $(this).addClass('play');
        } else {
            video.pause();
            $(this).removeClass('play');
        }
    });

    $(document).on('click', '[data-video-volume]', function () {
        let video = $(this).closest('[data-video]').find('video[data-video-content]').get(0);
        video.muted = !video.muted;

        if (video.muted) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    })

    $(document).on('click', '[data-video-resize]', function () {
        let video_html = $(this).closest('[data-video]').find('video[data-video-content]').get(0);
        let video_src = $(video_html).find('source[type="video/webm"]').attr('src');
        createVideo(video_src);
    })
});

function createVideo(video_src) {
    $('body')
        .width($(document).width())
        .css('overflow', 'hidden');

    let overlay = $('[data-video-full-overlay]');

    overlay.append('<div class="gallery__modal"><div class="gallery__close"></div><div class="video__full-content" data-video-content></div></div>');

    let video_modal = $('[data-video-full]');
    let video_content = video_modal.find('[data-video-content]');
    let new_video = $('<video controls><source src="' + video_src + '" type="video/webm"></video>');

    video_content.html(new_video);

    video_modal.addClass('open');

    $('.gallery__close').on('click', function () {
        removeVideo();
    });

    new_video.on('click', function (e) {
        e.stopPropagation();
    });

    overlay.on('click', function () {
        removeVideo();
    });

    overlay.fadeIn('fast');
}


function removeVideo() {
    $('[data-video-full-overlay]').fadeOut('fast', function () {
        $('.video__full .gallery__modal').remove();
        $('body')
            .css('overflow', 'auto')
            .width("auto")
    })
}