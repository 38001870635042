$('[data-toggle="gallery"]').gallery()

$(document).ready(function () {
    function rearrangeGallery() {

        if ($(window).width() < 1100) {
            let gallery = $('[data-students-gallery]');
            let gallery_slider = $('[data-students-slider]');
            let items = $('[data-students-gallery-item]').clone();
            gallery.empty();
            if ($(window).width() > 768) {

                gallery_slider.empty();

                let box1 = $('<div class="students__gallery-box"></div>');
                let section1 = $('<div class="students__gallery-section"></div>');
                section1.append(items.filter('[data-students-gallery-item="02"]'));
                box1.append(section1);
                gallery.append(box1);

                let box2 = $('<div class="students__gallery-box box--split"></div>');
                let section2 = $('<div class="students__gallery-section"></div>');
                section2.append(items.filter('[data-students-gallery-item="05"]'));
                section2.append(items.filter('[data-students-gallery-item="01"]'));
                box2.append(section2);

                let section3 = $('<div class="students__gallery-section"></div>');
                section3.append(items.filter('[data-students-gallery-item="03"]'));
                box2.append(section3);
                gallery.append(box2);

                let box3 = $('<div class="students__gallery-box box--split"></div>');
                let section4 = $('<div class="students__gallery-section"></div>');
                section4.append(items.filter('[data-students-gallery-item="07"]'));
                box3.append(section4);

                let section5 = $('<div class="students__gallery-section"></div>');
                section5.append(items.filter('[data-students-gallery-item="04"]'));
                section5.append(items.filter('[data-students-gallery-item="06"]'));
                box3.append(section5);
                gallery.append(box3);
            }
        }


        $('[data-toggle="gallery"]').gallery()

        if ($(window).width() <= 768) {
            $('[data-students-slider]').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplaySpeed: 1000,
                infinite: false,
                fade: true,
                arrows: false,
                dots: true,
                appendDots: $('[data-students-dots]')
            });
        }
    }

    rearrangeGallery();
    $(window).resize(rearrangeGallery);

});

