(function ($) {
    $.fn.gallery = function (options) {
        const settings = $.extend({
            imgWidth: 810, background: false, captionClass: 'gallery__caption', imageArray: [], activeImage: 0
        }, options);

        const jQueryMatchedObj = $(this);

        $(document)
            .on('click', function (event) {
                if ($(event.target).closest(".gallery__modal").length) return;
                removeModal();
                event.stopPropagation();
            })
            .on('keydown', function (event) {
                if (event.which === 27) removeModal();
            });

        function init() {
            start(this, jQueryMatchedObj);
            return false;
        }

        function start(click, obj) {
            settings.imageArray.length = 0;
            settings.activeImage = 0;

            for (let i = 0; i < obj.length; i++) {
                settings.imageArray.push([obj.eq(i).attr('href'), obj.eq(i).attr('title'), obj.eq(i).find('.' + settings.captionClass).text(), obj.eq(i).attr('data-date')]);
            }

            while (settings.imageArray[settings.activeImage][0] !== click.getAttribute('href')) {
                settings.activeImage++;
            }

            createModal();
            setImage();
        }

        function setImage() {

            $('.gallery__loader').show();
            $('.gallery__image').hide();

            let objImagePreloader = new Image();

            objImagePreloader.onload = function () {
                $('.gallery__image').attr('src', settings.imageArray[settings.activeImage][0]);
                _resize_container_image_box();
                objImagePreloader.onload = function () {
                };
            };

            objImagePreloader.src = settings.imageArray[settings.activeImage][0];
        }

        function _resize_container_image_box() {
            _show_image_data();
            setTitle();

            $('.gallery__loader').hide();
            _show_image();
        }

        function _show_image() {
            $('.gallery__image').fadeIn('fast');
            _set_navigation();
        }

        function _show_image_data() {
            $('.gallery__image-caption').text(settings.imageArray[settings.activeImage][2])
        }

        function _set_navigation() {
            $('.gallery__btn-prev')
                .off('click')
                .on('click', function () {
                    if (settings.activeImage === 0) {
                        settings.activeImage = settings.imageArray.length - 1;
                    } else {
                        settings.activeImage = settings.activeImage - 1;
                    }
                    setImage();
                    return false;
                });

            $('.gallery__btn-next, .gallery__image')
                .off()
                .on('click', function () {
                    if (settings.activeImage === settings.imageArray.length - 1) {
                        settings.activeImage = 0;
                    } else {
                        settings.activeImage = settings.activeImage + 1;
                    }
                    setImage();
                    return false;
                });
        }

        function createModal() {
            $('body')
                .width($(document).width())
                .css('overflow', 'hidden')
                .append('<div class="gallery__overlay"></div>');

            let vixOverlay = $('.gallery__overlay');

            if (settings.background) {
                vixOverlay.css('background-color', settings.background);
            }

            vixOverlay.append('<div class="gallery__modal"><div class="gallery__close"></div><button class="gallery__btn gallery__btn-prev"></button><button class="gallery__btn gallery__btn-next"></button><div class="gallery__image-wrap"><div class="gallery__loader"></div><img src="" alt="" class="gallery__image" /></div></div>');

            $('.gallery__close').on('click', function () {
                removeModal();
            });

            vixOverlay.fadeIn('fast');
        }

        function removeModal() {
            $('.gallery__overlay').fadeOut('fast', function () {
                $(this).remove();

                $('body')
                    .css('overflow', 'auto')
                    .width("auto")
            })
        }

        function setTitle() {
            $('.gallery__image-date').text(settings.imageArray[settings.activeImage][3]);
        }

        return $(this)
            .off('click')
            .on('click', init);
    };
})(jQuery);