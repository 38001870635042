$(document).ready(function () {
    let slider = $('[data-works-slider]')

    function initSlider() {
        slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 1000,
            prevArrow: $('[data-works-btn-prev]'),
            nextArrow: $('[data-works-btn-next]'),
            infinite: false,
            fade: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        fade: false,
                        centerMode: true,
                        centerPadding: '30px'
                    }
                },
            ]
        });
    }

    function createSliderItems(items, isSmallScreen) {
        let sliderSection = $('<div/>', {class: 'works__slider-items'});
        items.each(function (i, item) {
            let clonedItem = $(item).clone();
            clonedItem.removeClass('item--big').removeClass('item--small');
            if (isSmallScreen) {
                clonedItem.addClass('item--big');
                slider.append(clonedItem);
                sliderSection = $('<div/>', {class: 'works__slider-items'});
            } else {
                if (i % 2 === 0) {
                    clonedItem.addClass('item--big');
                } else {
                    clonedItem.addClass('item--small');
                }
                sliderSection.append(clonedItem);
                if (i % 2 !== 0) {
                    slider.append(sliderSection);
                    sliderSection = $('<div/>', {class: 'works__slider-items'});
                }
            }
        });
    }

    initSlider()

    if ($(window).width() <= 960) {
        let items = $('[data-works-item]');
        slider.slick('unslick');
        slider.empty();
        createSliderItems(items, $(window).width() <= 768);
        initSlider();
    }
});
