$(document).ready(function () {

    $('[data-faculty-slider]').on('beforeChange', function (event, slick, currentSlide) {
        closeDetailsWindows();
    });

    $('[data-faculty-details]').each(function () {
        let el = $(this);
        let span = el.find('.faculty__details-text span');
        let charCount = span.text().length;

        if ($(window).width() > 1490) {
            if (charCount > 750) {
                el.addClass('hide');
            }
        }
        if ($(window).width() > 1200 && $(window).width() <= 1490) {
            if (charCount > 800) {
                el.addClass('hide');
            }
        }
        if ($(window).width() > 960 && $(window).width() <= 1200) {
            if (charCount > 800) {
                el.addClass('hide');
            }
        }
        if ($(window).width() > 768 && $(window).width() <= 960) {
            if (charCount > 600) {
                el.addClass('hide');
            }
        }

        if ($(window).width() <= 768) {
            if (charCount > 800) {
                el.addClass('hide');
            }
        }
    });

    $(document).on('click', '[data-faculty-more]', function (e) {
        let el = $(this);
        let parent = el.closest('[data-faculty-details]');
        parent.removeClass('hide');
    })

    $(document).on('click', '[data-faculty-open-details]', function (e) {
        e.stopPropagation()
        let btn = $(this)
        let parent = btn.closest('[data-faculty-item]')
        let current_details = parent.find('[data-faculty-details]')
        let all_details = $('[data-faculty-details]')
        all_details.removeClass('open')

        if ($(window).width() < 768)
            removePositionClasses()

        if ($(window).width() >= 768 && $(window).width() < 1100) {
            removePositionClasses()
            let distance_to_left = btn.position().left;
            if (distance_to_left < 400) {
                current_details.addClass('position--left')
            } else if (distance_to_left > 600) {
                current_details.addClass('position--right')
            } else {
                current_details.addClass('position--center')
            }

        }
        current_details.addClass('open')
    });
    $(document).on('click', '[data-faculty-hide-details]', function (e) {
        closeDetailsWindows()
    });
    $(document).on('click', function (e) {
        let faculty_item = $('[data-faculty-item]')
        if (!faculty_item.is(e.target) && faculty_item.has(e.target).length === 0) {
            closeDetailsWindows()
        }
    });
});

function closeDetailsWindows() {
    let all_details = $('[data-faculty-details]')
    all_details.removeClass('open')
}

function removePositionClasses() {
    let all_details = $('[data-faculty-details]')
    all_details.removeClass('position--right')
    all_details.removeClass('position--left')
    all_details.removeClass('position--center')
}