$(document).ready(function () {
    let slider = $('[data-faculty-slider]')

    function initSlider() {
        slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 1000,
            prevArrow: $('[data-faculty-btn-prev]'),
            nextArrow: $('[data-faculty-btn-next]'),
            infinite: false,
            fade: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        fade: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        fade: false,
                        centerMode: true,
                        centerPadding: '30px'
                    }
                },
            ]
        });
    }


    initSlider();

    if ($(window).width() <= 960) {
        let items = $('[data-faculty-item]').clone();
        slider.slick('unslick');
        slider.empty();
        slider.append(items);
        initSlider();
    }
});
