$(document).ready(function() {
    $('[data-form-btn]').click(function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#offer-form").offset().top - 30
        }, 1000);
    });
    $('[data-contacts-btn]').click(function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#contacts").offset().top - 30
        }, 1000);
    });
});