$(document).ready(function () {
    let dropdown = $('[data-language-dropdown]');
    let dropdown_head = $('[data-language-dropdown-head]');
    let dropdown_item = $('[data-language-dropdown-item]');
    let dropdown_value = $('[data-language-dropdown-value]');

    dropdown_head.on('click', function () {
        dropdown.toggleClass('open');
    });

    dropdown_item.on('click', function () {
        let item_value = $(this).text();
        dropdown_value.text(item_value);
        dropdown.removeClass('open');
    });

    $(document).on('click', function (e) {
        if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) {
            dropdown.removeClass('open');
        }
    });
});
